<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    closable
    :loading="loading"
    submit-title="Delete"
    title="Delete the Type"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('delete', types, ids)"
  >
    <div class="mb-4">
      You are going to delete type <span
        v-for="(type, index) in types"
        :key="type"
        class="bold-600"
      >{{ type }}{{ index + 1 !== types.length ? ',' : '' }} </span> . <br> This action cannot be undone.
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  name: 'DeleteTypesModal',
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    types :{
      type: Array,
      default: () => []
    },
    ids :{
      type: Array,
      default: () => []
    },
  },
}
</script>
