<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    full-buttons
    :loading="loading"
    overflowing
    :side-valid="localTypesForMerge.length < 2"
    submit-title="Merge"
    title="Merge Types"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="merge"
  >
    <gl-input
      v-model="toType"
      class="mb-2"
      :height="40"
      label="New Type Name *"
      name="merge"
      rules="required|min:2"
      @input="checkTypeInMergedList"
    />
    <div
      v-if="localTypesForMerge.includes(intersectionType) || toTypeAlreadyExist"
      class="mb-2 mt-1 purple-text"
    >
      {{ intersectionType.name || toTypeAlreadyExist }} already exists
    </div>
    <div class="mb-4 flex">
      <gl-icon
        class="mr-2 gl-icon--disabled"
        :height="24"
        name="def-report"
        :width="24"
      />
      <div class="grey-text fs-12">
        Selected types will be replaced with the merged type and erased from the system.
      </div>
    </div>
    <gl-input
      v-model="score"
      v-mask="'###'"
      class="gl-modal__row"
      :height="40"
      label="type score *"
      name="score"
      rules="required|max_value:100"
      type="number"
      @input="handleScoring"
    />
    <div class="gl-input__label flex space-between">
      <div>
        types that will be merged *
      </div>
    </div>
    <vSelect
      v-model="type"
      class="mb-4"
      :clearable="false"
      name="type"
      :options="types"
      @input="addType"
    />

    <div
      v-for="(typeM, index) in localTypesForMerge"
      :key="index"
      class="mb-2"
    >
      <div
        class="flex align-center space-between"
        :class="{'intersection-type': intersectionType === typeM.name}"
      >
        <div class="ml-1">
          {{ typeM.name }}
        </div>
        <gl-icon
          class="pointer"
          :height="24"
          name="clear-large-dark"
          :width="24"
          @click="removeType(typeM.name)"
        />
      </div>
    </div>
    <confirmMergeModal
      v-if="openConfirmMergeModal"
      v-model="openConfirmMergeModal"
      :merged-types="localTypesForMerge"
      :type="toType"
      @close="openConfirmMergeModal = false"
      @confirm="confirmedMerge"
    />
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'
import GlIcon from '@/components/gl-icon'
import vSelect from 'vue-select'
import confirmMergeModal from "@/pages/scoring/modals/confirmMergeModal";

export default {
  components: {
    GlModal,
    GlInput,
    GlIcon,
    vSelect,
    confirmMergeModal,
  },
  inheritAttrs: false,
  props: {
    types: {
      type: Array,
      default: () => [],
    },
    typesForMerge: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toType: '',
      type: '',
      score: null,
      intersectionType: '',
      openConfirmMergeModal: false,
      toTypeAlreadyExist: false,
      localTypesForMerge: [],
    }
  },
  mounted() {
    this.localTypesForMerge = this.typesForMerge
  },
  methods: {
    merge() {
      if (this.toTypeAlreadyExist) {
        this.openConfirmMergeModal = true
        return
      }
      this.$emit('merge', this.toType, this.localTypesForMerge, this.score)
    },
    confirmedMerge() {
      this.$emit('merge', this.toType, this.localTypesForMerge, this.score)
      this.openConfirmMergeModal = false
      this.toTypeAlreadyExist = false
    },
    removeType(removingType) {
      const removeIndex = this.localTypesForMerge.findIndex(type => type.name === removingType)
      this.localTypesForMerge.splice(removeIndex, 1)
    },
    handleScoring(score) {
      if (score && Number(score) > 100) {
        this.score = 100
      }
    },
    checkTypeInMergedList() {
      this.intersectionType = this.localTypesForMerge.find(type => type.name === this.toType)
      this.toTypeAlreadyExist = this.types.find(type => type.name === this.toType)
    },
    addType(addingType) {
      if (!this.localTypesForMerge.find(type => type === addingType)) {
        this.localTypesForMerge.push(addingType)
        this.checkTypeInMergedList()
      } else {
        this.$toasted.global.error({ message: `This type has already been added`})
      }

      this.type = ''
    },
  },
}
</script>

<style>
.intersection-type {
  border: 1px solid var(--reflex-bluet);
  border-radius: 3px;
}
</style>
