<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    :loading="loading"
    submit-title="Edit"
    title="Edit Type"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('edit', type, score, id)"
  >
    <gl-input
      v-model="innerType"
      class="gl-modal__row"
      :disabled="forbidDeleteAndRename() || editOnlyScore()"
      :height="40"
      label="type name *"
      name="type"
      rules="required|min:2|max:75"
    />
    <gl-input
      v-model="innerScore"
      v-mask="'###'"
      class="gl-modal__row"
      :height="40"
      label="type score *"
      name="score"
      rules="required|max_value:100"
      type="number"
      @input="handleScoring"
    />
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'

export default {
  components: {
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    score: {
      type: [Number, String],
      default: '0',
    },
    id: {
      type: [Number, String],
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerType: {
      get: function () {
        return this.type
      },
      set: function (e) {
        this.$emit('change-type', e)
      },
    },
    innerScore: {
      get: function () {
        return this.score
      },
      set: function (e) {
        this.$emit('change-score', e)
      },
    },
  },
  methods: {
    forbidDeleteAndRename() {
      return this.status.includes('forbidDeleteAndRename')
    },
    editOnlyScore() {
      return this.status.includes('editOnlyScore')
    },
    handleScoring(score) {
      if (score && Number(score) > 100) {
        this.innerScore = 100
      }
    },
  },
}
</script>
